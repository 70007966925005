import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// ****************MASTERS*****************************

const ProductTypeMaster = Loadable(
  lazy(() => import("./pages/ProductTypeMaster"))
);

const ProductTypeMasterForm = Loadable(
  lazy(() => import("./pages/ProductTypeMasterForm"))
);

const ProductMaster = Loadable(lazy(() => import("./pages/ProductMaster")));

const ProductMasterForm = Loadable(
  lazy(() => import("./pages/ProductMasterForm"))
);

const WarehouseTypeMaster = Loadable(
  lazy(() => import("./pages/WarehouseTypeMaster"))
);

const WarehouseMasterForm = Loadable(
  lazy(() => import("./pages/WarehouseMasterForm"))
);

const WareHouseStock = Loadable(lazy(() => import("./pages/WareHouseStock")));

const WarehouseRegistration = Loadable(
  lazy(() => import("./pages/WarehouseRegistration"))
);
const WarehouseRegistrationForm = Loadable(
  lazy(() => import("./pages/WarehouseRegistrationForm"))
);
const ProductRegistration = Loadable(
  lazy(() => import("./pages/ProductRegistration"))
);
const ProductRegistrationForm = Loadable(
  lazy(() => import("./pages/ProductRegistrationForm"))
);

const WarehouseStockForm = Loadable(
  lazy(() => import("./pages/WarehouseStockForm"))
);

const StateMaster = Loadable(lazy(() => import("./pages/StateMaster")));

const StateMasterForm = Loadable(lazy(() => import("./pages/StateMasterForm")));

const CityMaster = Loadable(lazy(() => import("./pages/CityMaster")));

const CityMasterForm = Loadable(lazy(() => import("./pages/CityMasterForm")));

const UserRoleMaster = Loadable(lazy(() => import("./pages/UserRoleMaster")));

const UserRoleMasterForm = Loadable(
  lazy(() => import("./pages/UserRoleMasterForm"))
);
const RoleMaster = Loadable(lazy(() => import("./pages/RoleMaster")));
const RoleMasterForm = Loadable(lazy(() => import("./pages/RoleMasterForm")));
// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const UserGrid = Loadable(
  lazy(() => import("./pages/userManagement/UserGrid"))
);
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "product-type-master",
        element: <ProductTypeMaster />,
      },
      {
        path: "product-type-master-form",
        element: <ProductTypeMasterForm />,
      },
      {
        path: "product-master",
        element: <ProductMaster />,
      },
      {
        path: "edit-product-master-form/:id",
        element: <ProductMasterForm />,
      },
      {
        path: "edit-state-master-form/:id",
        element: <StateMasterForm />,
      },
      {
        path: "edit-city-master-form/:id",
        element: <CityMasterForm />,
      },
      {
        path: "edit-product-type-master-form/:id",
        element: <ProductTypeMasterForm />,
      },
      {
        path: "edit-user-role-master-form/:id",
        element: <UserRoleMasterForm />,
      },
      {
        path: "edit-warehouse-master-form/:id",
        element: <WarehouseMasterForm />,
      },
      {
        path: "product-master-form",
        element: <ProductMasterForm />,
      },
      // {
      //   path: "product-master-form/:id",
      //   element: <ProductMasterForm id={props.id} />,
      // },
      {
        path: "warehouse-type-master",
        element: <WarehouseTypeMaster />,
      },
      {
        path: "warehouse-registration",
        element: <WarehouseRegistration />,
      },
      {
        path: "warehouse-stock",
        element: <WareHouseStock />,
      },
      {
        path: "warehouse-registration-form",
        element: <WarehouseRegistrationForm />,
      },
      {
        path: "edit-warehouse-registration-form/:id",
        element: <WarehouseRegistrationForm />,
      },
      {
        path: "warehouse-stock-form/:product_storage_id",
        element: <WarehouseStockForm />,
      },
      {
        path: "warehouse-stock-form",
        element: <WarehouseStockForm />,
      },
      {
        path: "Product-registration",
        element: <ProductRegistration />,
      },
      {
        path: "Product-registration-form",
        element: <ProductRegistrationForm />,
      },
      {
        path: "edit-Product-registration-form/:id",
        element: <ProductRegistrationForm />,
      },
      {
        path: "warehouse-master-form",
        element: <WarehouseMasterForm />,
      },
      {
        path: "state-master",
        element: <StateMaster />,
      },
      {
        path: "state-master-form",
        element: <StateMasterForm />,
      },
      {
        path: "city-master",
        element: <CityMaster />,
      },
      {
        path: "city-master-form",
        element: <CityMasterForm />,
      },
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "user-role-master",
        element: <UserRoleMaster />,
      },
      {
        path: "user-role-master-form",
        element: <UserRoleMasterForm />,
      },
      {
        path: "role-master",
        element: <RoleMaster />,
      },
      {
        path: "role-master-form",
        element: <RoleMasterForm />,
      },
      {
        path: "edit-role-master-form/:id",
        element: <RoleMasterForm />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
