import Icons from "icons/sidebar";

const index = [
  // {
  //   title: "Dashboard",
  //   Icon: Icons.DashboardIcon,
  //   path: "/dashboard",
  //   yes: false,
  //   submenue: [],
  // },
  {
    title: "Master",
    Icon: Icons.UserGridIcon,
    path: "/master",
    yes: false,
    submenue: [
      {
        title: "State Master",
        path: "/dashboard/state-master",
        yes: true,
      },
      {
        title: "City Master",
        path: "/dashboard/city-master",
        yes: true,
      },
      {
        title: "Product Type Master",
        path: "/dashboard/product-type-master",
        yes: true,
      },
      {
        title: "Product Master",
        path: "/dashboard/product-master",
        yes: true,
      },
      {
        title: "Warehouse Type Master",
        path: "/dashboard/warehouse-type-master",
        yes: true,
      },
      {
        title: "User Role Master",
        path: "/dashboard/user-role-master",
        yes: true,
      },
      {
        title: "Role Master",
        Icon: Icons.AddUserIcon,
        path: "/dashboard/role-master",
        yes: true,
      },
    ],
  },
  {
    title: "Forms",
    Icon: Icons.DashboardIcon,
    path: "/master",
    yes: false,
    submenue: [
      {
        title: "Warehouse Form",
        Icon: Icons.UserProfileIcon,
        path: "/dashboard/warehouse-registration",
        yes: true,
      },
      {
        title: "Warehouse Products",
        Icon: Icons.UserProfileIcon,
        path: "/dashboard/warehouse-stock",
        yes: true,
      },
      {
        title: "Product Form",
        Icon: Icons.UserProfileIcon,
        path: "/dashboard/product-registration",
        yes: true,
      },
      // {
      //   title: "User Grid",
      //   Icon: Icons.UserGridIcon,
      //   path: "/dashboard/user-grid",
      //   yes: true,
      // },
      // {
      //   title: "User List",
      //   Icon: Icons.UserManagementIcon,
      //   path: "/dashboard/user-list",
      //   yes: true,
      // },
      // {
      //   title: "Add user",
      //   Icon: Icons.AddUserIcon,
      //   path: "/dashboard/add-user",
      //   yes: true,
      // },
    ],
  },
  {
    title: "Login",
    Icon: Icons.LoginIcon,
    path: "/login",
    yes: true,

    submenue: [],
  },
  {
    title: "Register",
    Icon: Icons.SessionsIcon,
    path: "/Register",
    yes: true,
    submenue: [],
  },
];

export default index;
